import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { format, parseISO } from 'date-fns'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { PageLayout } from '../layouts'
import { PageHeader } from '../components'
import * as styles from '../styles/pages/positionTemplate'
import * as base from '../styles/base'
import variables from '../styles/variables'

const propTypes = {
  data: PropTypes.shape({
    frontmatter: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      introduction: PropTypes.string,
      date: PropTypes.string
    })
  }).isRequired
}

export default function PositionTemplate({ data }) {
  const { frontmatter, body } = data.mdx

  const navbarProps = {
    background: variables.colors.oracle.dark,
  }

  const entryDate = format(parseISO(frontmatter.date), 'MMMM d, yyyy')
  const firstChild = (
    <PageHeader
      header={frontmatter.title}
      info="Open positions"
    />
  )
  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={navbarProps}
      description={frontmatter.metaDescription}
      sharingTitle={`Aito.ai - ${frontmatter.title}`}
    >
      <Helmet>
        <title>{`Aito.ai - ${frontmatter.title}`}</title>
      </Helmet>
      <styles.InfoCircle>
        <styles.IntroIcon />

        <styles.Date>{entryDate}</styles.Date>
      </styles.InfoCircle>

      <styles.Container>
        <base.MdContainer>
          <MDXRenderer>
            {body}
          </MDXRenderer>

          <styles.StyledLink to="/company/#careers/">
            <styles.BackArrow />
            Back to open positions
          </styles.StyledLink>
        </base.MdContainer>
      </styles.Container>
    </PageLayout>
  )
}

PositionTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query PositionByPath($id: String!) {
    mdx( id: { eq: $id } ) {
      id
      body
      frontmatter {
        path
        title
        date
        introduction
        metaDescription
      }
    }
  }
`
